import { Controller } from "@hotwired/stimulus"
import { loadStripe } from "@stripe/stripe-js/pure"

export default class extends Controller {
  static targets = ["cardElement", "postalCodeElement", "cardErrors", "price"]
  static values = {
    apiKey: String,
    clientSecret: String,
    cardNumberLabel: String,
    account: String,
  }

  async connect() {
    const options = this.accountValue ? { stripeAccount: this.accountValue } : {}
    this.stripe = await loadStripe(this.apiKeyValue, options)
    let elements = this.stripe.elements({
      fonts: [{
        cssSrc: "https://fonts.googleapis.com/css2?family=Open+Sans&family=Overpass",
      }],
    })
    let style = {
      base: {
        iconColor: "#666EE8",
        color: "#31325F",
        fontWeight: 400,
        fontFamily: "Overpass, sans-serif",
        lineHeight: "40px",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    }

    this.cardNumber = elements.create("cardNumber", {
      style: style,
      showIcon: true,
      placeholder: this.cardNumberLabelValue,
    })
    this.cardNumber.mount("#card-number-element")

    let cardExpiryElement = elements.create("cardExpiry", {
      style: style,
      placeholder: "MM/YY",
    })
    cardExpiryElement.mount("#card-expiry-element")

    let cardCvcElement = elements.create("cardCvc", {
      style: style,
      placeholder: "CVC",
    })
    cardCvcElement.mount("#card-cvc-element")

    // Handle real-time validation errors from the card Element.
    let controller = this
    this.cardNumber.addEventListener("change", (event) => {
      let displayError = controller.cardErrorsTarget
      displayError.textContent = event?.error ? error.message : ""
    })
  }

  handleSubmit(event) {
    event.preventDefault()

    let options = {
      address_zip: this.postalCodeElementTarget.value,
    }

    const controller = this

    this.stripe.confirmCardPayment(this.clientSecretValue, {
      payment_method: {
        card: this.cardNumber,
        billing_details: {}
      }
    }).then((result) => {
      const displayResult = document.getElementById("submit-payment-result")
      if (result.error) {
        const displayError = document.getElementById("card-errors")
        displayError.innerHTML = "<i class='fa-regular fa-circle-exclamation me-1'></i>" + result.error.message
      } else {
        // Need to send the result.paymentIntent.id to the server and update the appointment.
        // We can do this by submitting a form to the server?

        if (result.paymentIntent.status === "succeeded") {
          displayResult.textContent = "Payment succeeded!"

          const form = document.querySelector("form.new_payment")

          let input = document.createElement("input")
          input.setAttribute("type", "hidden")
          input.setAttribute("name", "payment[payment_method_id]")
          input.setAttribute("value", result.paymentIntent?.payment_method)

          form.appendChild(input)

          const stripeIntent = document.getElementById("payment_stripe_payment_intent_id")
          const paymentAmount = document.getElementById("payment_payment_amount")
          stripeIntent.value = result.paymentIntent.id
          paymentAmount.value = result.paymentIntent.amount

          form.submit()
        }
      }
    })
  }
}
